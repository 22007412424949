const reducer = (state = {}, action) => { 

    switch (action.type) {

        default:
        
            return state;

        }
    };
    
export default reducer;