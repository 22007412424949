import React from 'react';

import { Template } from './';

class Home extends React.Component {

    render() {

        return <Template title={'Imprint'}>
            <div className="row">
                <div className="col-12">

                    <ul>
                        <li><strong>dormakaba Deutschland GmbH</strong></li>
                        <li>DORMA Platz 1</li>
                        <li>D-58256 Ennepetal</li>
                        <li>T: +49 2333 793-0</li>
                        <li>F: +49 2333 793-4950</li>
                        <li>E: marketing.de@dormakaba.com</li>
                        <li>I: www.dormakaba.de</li>
                    </ul>


                    <p><strong>Vertretungsberechtigte Geschäftsführer:</strong></p>

                    <ul>
                        <li>Michael Hensel</li>
                        <li>Alwin Berninger</li>
                        <li>Jörg Henke</li>
                    </ul>

                    <ul>
                        <li><strong>Registergericht:</strong> Amtsgericht Hagen</li>
                        <li><strong>Registernummer:</strong> HRB 9635</li>
                    </ul>

                    <p>
                        <strong>Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:</strong> DE-126455151
                    </p>



                </div>
            </div>
        </Template>
    }
}

    export default Home