import React from 'react';

import {Link} from 'react-router-dom';

import vdcLogo from '../../img/vdc-logo.svg';

import dokaLogo from '../../img/dormakaba-logo.svg';

class Home extends React.Component {

    render() {

        return(

            <div className={'container'}>

                <div className="row">

                    <div className="col-2 py-3" style={{paddingLeft:'2rem'}}>

                        <img src={dokaLogo} alt="Dormakaba logo"/>

                    </div>

                </div>

                <div className="row justify-content-center" style={{minHeight: '30rem'}}>

                    <div className="col-4 bg-primary text-white d-flex align-items-center" style={{paddingLeft:'2rem'}}>

                        <div>
                            <h2 className={'mb-5'} style={{fontWeight: '400'}}>
                                dormakaba <br/>
                                Virtual Design Center Interactive <br/> <br/>
                                Control Panel
                            </h2>

                            <Link to={'/sign-in'} className={'btn btn-outline-light px-5'}>Login</Link>

                        </div>

                    </div>

                    <div className="col-8 d-flex bg-danger text-white">

                        <div className="row w-100 h-100 align-items-center" style={{margin:'0'}}>

                            <div className="col-12" style={{textAlign:'center', marginTop:'5rem'}}>
                                
                                <img src={vdcLogo} alt="VDC logo" style={{width: '66%'}}/>

                            </div>

                            <div className="w-100"></div>

                            <div className="col align-self-end" style={{textAlign:'end', marginBottom:'1rem'}}>
                                
                                <Link to={'/admin/imprint'} className="text-white">Imprint and Privacy</Link>

                            </div>

                        </div>

                    </div>

                </div>

                <div className="row">

                    <div className="col">



                    </div>

                </div>

            </div>


        )
    }
}

export default Home