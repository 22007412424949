import {
    ActionHelper,
} from '@solidwhite/swcp-base';

class Actions extends ActionHelper {

    constructor() {
        super({singular: 'custom-post-sign-up'});
    }

}

const actions = new Actions();

export default actions