import React from 'react';

import { CustomPostSignUpManager } from './components';

export default {
    list: {
        path: 'custom-post-sign-up',
        //icon: 'build',
        title: 'custom-post-sign-up',
        private: false,
        //level: 0,
        component: (props) =>
            <CustomPostSignUpManager />
        ,
    },
};