import "@babel/polyfill";

import 'whatwg-fetch';

import React from 'react';

import ReactDOM from 'react-dom';

import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';

import {
    ControlPanel
} from '@solidwhite/swcp-base/';

import {
    Home,
} from './js/views';

import './sass/_app.scss';

import '@solidwhite/swcp-base/lib/fonts/icons.css';

import config from './js/config';

import packageJson from '../package.json';

import outdatedBrowserRework from 'outdated-browser-rework';

console.log("swcp-base: " + packageJson.dependencies["@solidwhite/swcp-base"]);

outdatedBrowserRework({
    browserSupport: {
        'Chrome': 57, // Includes Chrome for mobile devices
        'Edge': 39,
        'Safari': 10,
        'Mobile Safari': 10,
        'Firefox': 50,
		'Opera': false,
		'Vivaldi': false,
        'IE': 11
    },
    requireChromeOnAndroid: false,
    isUnknownBrowserOK: false, 
    messages: {
        en: {
            outOfDate: "Your browser version is not supported anymore.<br/>Please buy a new one ;)",
            unsupported: "Your browser is not supported.<br/>Please use a real one ;)",
        },
        de: {
            outOfDate: "Your browser version is not supported anymore.<br/>Please buy a new one ;)",
            unsupported: "Your browser is not supported.<br/>Please use a real one ;)",
        }
    }
});

ReactDOM.render((

    <BrowserRouter>

        <Switch>

            <Route path={'/admin'} exact={false} component={() => <ControlPanel config={config} prefix={'admin'}/>}/>

            <Redirect path={'/forgot-password'} exact={true} to={'/admin/forgot-password'}/>

            <Redirect path={'/sign-in'} exact={true} to={'/admin/sign-in'}/>

            <Route path={'/sign-up'} exact={true} render={(props) => <Redirect to={`/admin/sign-up${props.location.search}`}/>}/>

            <Redirect path={'/new-password'} exact={true} to={'/admin/new-password'}/>

            <Route path={'/'} exact={true} component={Home}/>

        </Switch>

    </BrowserRouter>

), document.getElementById('root'));