import * as auth from '@solidwhite/swcp-base/lib/js/modules/auth';

import * as users from '@solidwhite/swcp-base/lib/js/modules/users';

import * as analytics from '@solidwhite/swcp-base/lib/js/modules/analytics';

import * as groups from '@solidwhite/swcp-base/lib/js/modules/groups';

import * as invitations from '@solidwhite/swcp-base/lib/js/modules/invitations';

import * as optinentries from '@solidwhite/swcp-base/lib/js/modules/optinentries';

import * as permissions from '@solidwhite/swcp-base/lib/js/modules/permissions';

import * as downloadapp from '@solidwhite/swcp-base/lib/js/modules/downloadapp';

import * as ImprintAndPrivacy from '../modules/imprint';

import * as CustomSignUpPage from '../modules/custompostsignuppage';

export default {
    api: process.env.REACT_APP_API,
    routes: [],
    modules: [
        {
            name: 'auth',
            module: auth,
            config: {
                identityPoolId: process.env.REACT_APP_IDENTITYPOOL_ID,
                region: process.env.REACT_APP_REGION,
                userPoolId: process.env.REACT_APP_USERPOOL_ID,
                userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
            },
            postSignUpConfig: {
                enabled: true,
                customOverridePath: 'custom-post-sign-up',
                downloadLink: process.env.REACT_APP_APPLICATION_DOWNLOAD_LINK,
            }
        },
        {
            name: 'custom-post-sign-up',
            module: CustomSignUpPage,
        },
        {
            name: 'users',
            module: users,
            expand: 'UserGroups.Group',
            schema: {
                Id: {
                    visible: false,
                },
                Sub: {
                    visible: false,
                },
                EmailVerified: {
                    visible: false,
                },
                MiddleName: {
                    visible: false,
                },
                PhoneNumber: {
                    visible: false,
                },
                PhoneNumberVerified: {
                    visible: false,
                },
                Nickname: {
                    visible: false,
                },
                Birthdate: {
                    visible: false,
                },
                Gender: {
                    visible: false,
                },
                ZoneInfo: {
                    visible: false,
                },
                Locale: {
                    visible: false,
                },
                Type: {
                    visible: false,
                },
                UserPermissions: {
                    visible: false,
                },
                LastActiveAt: {
                    visible: false,
                },
                UpdatedAt: {
                    visible: false,
                },
                CreatedAt: {
                    visible: false,
                },
            },
        },
        {
            name: 'groups',
            module: groups,
            expand: 'GroupPermissions.Permission',
            schema: {
                Description: {
                    visible: true,
                },
                Id: {
                    visible: false,
                },
                Rank: {
                    visible: false,
                },
                CreatorType: {
                    visible: false,
                },
                OwnerId: {
                    visible: false,
                },
                CreatedAt: {
                    visible: false,
                },
                UpdatedAt: {
                    visible: false,
                },
            },
        },
        {
            name: 'permissions',
            module: permissions,
            schema: {
                Id: {
                    visible: false,
                },
                CreatorType: {
                    visible: false,
                },
                CreatedAt: {
                    visible: false,
                },
                UpdatedAt: {
                    visible: false,
                },
            },
        },
        {
            name: 'invitations',
            module: invitations,
            schema: {
                Id: {
                    visible: false,
                },
            },
        },
        {
            name: 'optinentries',
            module: optinentries,
            list: {
                schema: {
                    Text: {
                        visible: false,
                    },
                    Id: {
                        visible: false,
                    },
                    UpdatedAt: {
                        visible: false,
                    },
                },
            },
        },
        {
            name: 'analytics',
            module: analytics,
            dashboard: {
                "id": "6s5d4f35sd1f3s5sd4f",
                "title": "Default Analytics Dashboard",
                "widgets": [
                    {
                        "title": "Used Download Traffic",
                        "type": "Number",
                        "format": "GB",
                        "input": "Byte",
                        "precision": 2,
                        "width": 3,
                        "params": {
                            "metric": "aws-s3",
                            "dimension": "current-download-traffic",
                            "startDate": "startOfMonth",
                        }
                    },
                    {
                        "title": "Used User Licenses",
                        "type": "Number",
                        "width": 1,
                        "params": {
                            "metric": "user",
                            "dimension": "current-user-count",
                        }
                    }
                ]
            },
        },
        {
            name: 'downloadapp',
            module: downloadapp,
            downloadLink: process.env.REACT_APP_APPLICATION_DOWNLOAD_LINK,
            headerImage: 'vdc_download_banner.jpg',
        },
        {
            name: 'imprint',
            module: ImprintAndPrivacy,
        },
    ],
}