import React, { Component } from 'react';

import { connect } from 'react-redux';

import vdcLogoRed from '../../../../img/vdc-logo-red.png';

import dokaLogo from '../../../../img/dormakaba-logo.gif';

const mapStateToProps = state => ({

    auth: state.auth,
    invitations: state.invitations,

});

class CustomPostSignUpManager extends Component {
       
    render() {

        const {
            auth,
            //invitations,
        } = this.props;

        //let showWarning = false;
        //showWarning = invitations.single.data.CanAlterEmail;

        let downloadLink = null;
        downloadLink = auth.postSignUpConfig.downloadLink;

        //console.log("Download link:");
        //console.log(downloadLink);

        //console.log("Show check email warning?");
        //console.dir(showWarning);

        return (

            <div className="container-fluid">

                <div className="row justify-content-center" style={{marginTop:'3rem'}}>

                    <div className="col-3">

                        <img src={vdcLogoRed} alt="VDC logo"/>


                    </div>

                </div>



                <div className="row justify-content-center">

                    <div className="col-2">

                    <img src={dokaLogo} alt="Dormakaba logo"/>


                    </div>

                </div>



                <div className="row justify-content-center mt-5 customContent">

                    <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6 justify-content-center"
                        style={{marginTop:'2rem',textAlign:'center'}}>

                        <div className="alert alert-success" role="alert">
                            <h5 className="text-dark">Your account was successfully created.</h5>
                        </div>

                        <div className="alert alert-warning" role="alert">
                            <h5 className="text-dark">Please check your inbox and verify your email.</h5>
                        </div>

                        <div className={'d-flex mb-3 justify-content-center'}
                            style={{marginTop:'6rem'}}>

                            {!downloadLink && <button style={{minWidth:'250px'}}
                                                type="button"
                                                className="btn btn-lg btn-primary shadow-lg" disabled>
                                
                                App not available
    
                            </button>}

                            {downloadLink && <a href={downloadLink}
                                download="We love cats">

                                <button style={{width:'250px',marginBottom:'0px'}}
                                    type="button"
                                    className="btn btn-lg btn-primary">
                                    
                                    Download app

                                </button>

                            </a>}

                        </div>

                        <div className={'d-flex mb-3 justify-content-center'}
                            style={{margin:'1rem'}}>

                            <a href="/admin/sign-in">

                                <button style={{width:'250px'}}
                                    type="button"
                                    className="btn btn-lg btn-primary">
                                    
                                    Go to login

                                </button>

                            </a>

                        </div>

                    </div>

                </div>

            </div>

        );

    }
}

export default connect(mapStateToProps, null)(CustomPostSignUpManager);