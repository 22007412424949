import React from 'react';

import {Template} from './';

class Home extends React.Component {

    render() {

        return (

            <Template title={'Datenschutzerklärung'}>

                <div className="article-content">
                    <p>(1)&nbsp;&nbsp; Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei
                        Nutzung
                        unserer Website. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind,
                        z.
                        B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.</p><p>(2)&nbsp;&nbsp; Verantwortlicher gem.
                    Art.&nbsp;4 Abs.&nbsp;7 EU-Datenschutz-Grundverordnung (DS-GVO) ist</p><p>dormakaba International
                    Holding GmbH<br/>DORMA Platz 1<br/>58256 Ennepetal, Deutschland</p><p>Unseren
                    Datenschutzbeauftragten
                    erreichen Sie unter <a href="mailto:data.protection@dormakaba.com"
                                           target="_blank" rel="noopener noreferrer">data.protection@dormakaba.com</a> oder unserer Postadresse
                    mit
                    dem Zusatz „der Datenschutzbeauftragte“.</p><p>(3)&nbsp;&nbsp; Bei Ihrer Kontaktaufnahme mit uns per
                    E-Mail oder über ein Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse,
                    ggf.
                    Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem
                    Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist,
                    oder
                    schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.</p>
                    <p>(4)&nbsp;&nbsp; Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister
                        zurückgreifen oder Ihre Daten für werbliche Zwecke nutzen möchten, werden wir Sie untenstehend
                        im
                        Detail über die jeweiligen Vorgänge informieren. Dabei nennen wir auch die festgelegten
                        Kriterien
                        der Speicherdauer.</p><p><strong>B.&nbsp;&nbsp;&nbsp; </strong><strong>Ihre Rechte</strong></p>
                    <p>Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen
                        Daten:</p>
                    <ul className="rte--list">
                        <li>Recht auf Auskunft,</li>
                        <li>Recht auf Berichtigung oder Löschung,</li>
                        <li>Recht auf Einschränkung der Verarbeitung,</li>
                        <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
                        <li>Recht auf Datenübertragbarkeit,</li>
                        <li>Recht auf Beschwerde bei einer Aufsichtsbehörde.</li>
                    </ul>
                    <p>&nbsp;​​​​​​​<strong>C.&nbsp;&nbsp;&nbsp; </strong><strong>Erhebung personenbezogener Daten bei
                        Besuch unserer Website</strong></p><p>(1)&nbsp;&nbsp; Bei der bloss informatorischen Nutzung der
                    Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen übermitteln,
                    erheben
                    wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere
                    Website betrachten möchten, erheben wir die folgenden Daten, die für uns technisch erforderlich
                    sind, um
                    Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage
                    ist
                    Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f DS-GVO):</p>
                    <ul className="rte--list">
                        <li>IP-Adresse,</li>
                        <li>Datum und Uhrzeit der Anfrage,</li>
                        <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT),</li>
                        <li>Inhalt der Anforderung (konkrete Seite),</li>
                        <li>Zugriffsstatus/HTTP-Statuscode,</li>
                        <li>jeweils übertragene Datenmenge,</li>
                        <li>Website, von der die Anforderung kommt,</li>
                        <li>Browser,</li>
                        <li>Betriebssystem und dessen Oberfläche,</li>
                        <li>Sprache und Version der Browsersoftware.</li>
                    </ul>
                    <p>(2)&nbsp;&nbsp; Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website
                        Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die
                        auf
                        Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch
                        welche
                        der Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen zufliessen. Cookies
                        können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das
                        Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen.</p>
                    <p>(3)&nbsp;&nbsp; Einsatz
                        von Cookies:</p><p>a)&nbsp;&nbsp;&nbsp; Diese Website nutzt folgende Arten von Cookies, deren
                    Umfang und
                    Funktionsweise im Folgenden erläutert werden:</p>
                    <ul className="rte--list">
                        <li>Transiente Cookies (dazu b)</li>
                        <li>Persistente Cookies (dazu c).</li>
                    </ul>
                    <p>b)&nbsp;&nbsp;&nbsp; Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser
                        schliessen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte
                        Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung
                        zuordnen
                        lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren.
                        Die
                        Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schliessen.</p>
                    <p>c)&nbsp;&nbsp;&nbsp;&nbsp; Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer
                        gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den
                        Sicherheitseinstellungen Ihres Browsers jederzeit löschen.</p><p>d)&nbsp;&nbsp;&nbsp; Sie können
                    Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von
                    Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht
                    alle Funktionen dieser Website nutzen können.</p><p>e)&nbsp;&nbsp;&nbsp; Die genutzten Flash-Cookies
                    werden nicht durch Ihren Browser erfasst, sondern durch Ihr Flash-Plug-in. Weiterhin nutzen wir
                    HTML5
                    storage objects, die auf Ihrem Endgerät abgelegt werden. Diese Objekte speichern die erforderlichen
                    Daten unabhängig von Ihrem verwendeten Browser und haben kein automatisches Ablaufdatum. Wenn Sie
                    keine
                    Verarbeitung der Flash-Cookies wünschen, müssen Sie ein entsprechendes Add-On installieren. Die
                    Nutzung
                    von HTML5 storage objects können Sie verhindern, indem Sie in Ihrem Browser den privaten Modus
                    einsetzen. Zudem empfehlen wir, regelmässig Ihre Cookies und den Browser-Verlauf manuell zu
                    löschen.</p>
                    <p>f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Diese Website benutzt Google Analytics, einen Webanalysedienst
                        der
                        Google Inc. („Google“).</p><p><strong>D.&nbsp;&nbsp;&nbsp; </strong><strong>Google
                    Analytics</strong></p><p>(1)&nbsp;&nbsp; Google Analytics verwendet sog. „Cookies“, Textdateien, die
                    auf
                    Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie
                    ermöglichen.
                    Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel
                    an
                    einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der
                    IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von
                    Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
                    Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
                    Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website
                    wird
                    Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
                    Website-Aktivitäten zusammenzustellen und um weitere mit der Website-Nutzung und der Internetnutzung
                    verbundene Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.</p><p>(2)&nbsp;&nbsp; Sie
                    können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
                    verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                    Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die
                    Erfassung
                    der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
                    IP-Adresse)
                    an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem
                    folgenden
                    Link verfügbare Browser-Plug-in herunterladen und installieren: <a
                        href="https://tools.google.com/dlpage/gaoptout?hl=de"
                        target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a>.</p><p>(3)&nbsp;&nbsp; Diese
                    Website verwendet Google Analytics mit der Erweiterung „_anonymizeIp()“. Dadurch werden IP-Adressen
                    gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den
                    über
                    Sie erhobenen Daten ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die
                    personenbezogenen Daten damit umgehend gelöscht.</p><p>(4)&nbsp;&nbsp; Wir nutzen Google Analytics,
                    um
                    die Nutzung unserer Website analysieren und regelmässig verbessern zu können. Über die gewonnenen
                    Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten.
                    Für
                    die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen werden, hat sich Google dem
                    EU-US Privacy Shield unterworfen, <a href="https://www.privacyshield.gov/EU-US-Framework"
                                                         target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>.
                    Rechtsgrundlage für die Nutzung von Google Analytics ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f
                    DS-GVO.</p><p>(5)&nbsp;&nbsp; Informationen des Drittanbieters: Google Dublin, Google Ireland Ltd.,
                    Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001. Nutzerbedingungen: <a
                        href="https://www.google.com/analytics/terms/de.html"
                        target="_blank" rel="noopener noreferrer">https://www.google.com/analytics/terms/de.html</a>, Übersicht zum
                    Datenschutz: <a
                        href="https://support.google.com/analytics/answer/6004245?hl=de"
                        target="_blank" rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</a>, sowie die
                    Datenschutzerklärung: <a
                        href="http://www.google.de/intl/de/policies/privacy">https://policies.google.com/privacy?hl=de&amp;gl=de</a>.<br/>
                </p><p><strong>E. Newsletter</strong></p><p>(1) Mit Ihrer Einwilligung können Sie unseren Newsletter
                    abonnieren, mit dem wir Sie über unsere aktuellen interessanten Angebote informieren. Die beworbenen
                    Waren und Dienstleistungen sind in der Einwilligungserklärung benannt.</p><p>(2) Für die Anmeldung
                    zu
                    unserem Newsletter verwenden wir das sog. Double-opt-in-Verfahren. Das heisst, dass wir Ihnen nach
                    Ihrer
                    Anmeldung eine E-Mail an die angegebene E-Mail-Adresse senden, in welcher wir Sie um Bestätigung
                    bitten,
                    dass Sie den Versand des Newsletters wünschen. Wenn Sie Ihre Anmeldung nicht innerhalb von
                    24&nbsp;Stunden bestätigen, werden Ihre Informationen gesperrt und nach einem Monat automatisch
                    gelöscht. Darüber hinaus speichern wir jeweils Ihre eingesetzten IP-Adressen und Zeitpunkte der
                    Anmeldung und Bestätigung. Zweck des Verfahrens ist, Ihre Anmeldung nachweisen und ggf. einen
                    möglichen
                    Missbrauch Ihrer persönlichen Daten aufklären zu können.</p><p>(3) Pflichtangabe für die Übersendung
                    des
                    Newsletters ist allein Ihre E-Mail-Adresse. Die Angabe weiterer, gesondert markierter Daten ist
                    freiwillig und wird verwendet, um Sie persönlich ansprechen zu können. Nach Ihrer Bestätigung
                    speichern
                    wir Ihre E-Mail-Adresse zum Zweck der Zusendung des Newsletters. Rechtsgrundlage ist Art.&nbsp;6
                    Abs.&nbsp;1 S.&nbsp;1 lit. a DS-GVO.</p><p>(4) Ihre Einwilligung in die Übersendung des Newsletters
                    können Sie jederzeit widerrufen und den Newsletter abbestellen. Den Widerruf können Sie durch Klick
                    auf
                    den in jeder Newsletter-E-Mail bereitgestellten Link oder durch eine Nachricht an die in oben
                    stehenden
                    Kontaktdaten erklären.</p><p>(5) Wir weisen Sie darauf hin, dass wir bei Versand des Newsletters Ihr
                    Nutzerverhalten auswerten. Für diese Auswertung beinhalten die versendeten E-Mails sogenannte
                    Web-Beacons bzw. Tracking-Pixel, die Ein-Pixel-Bilddateien darstellen, die auf unserer Website
                    gespeichert sind. Für die Auswertungen verknüpfen wir die in §&nbsp;3 genannten Daten und die
                    Web-Beacons mit Ihrer E-Mail-Adresse und einer individuellen ID.</p><p>Auch im Newsletter erhaltene
                    Links enthalten diese ID. Mit den so gewonnen Daten erstellen wir ein Nutzerprofil, um Ihnen den
                    Newsletter auf Ihre individuellen Interessen zuzuschneiden. Dabei erfassen wir, wann Sie unsere
                    Newsletter lesen, welche Links Sie in diesen anklicken und folgern daraus Ihre persönlichen
                    Interessen.
                    Diese Daten verknüpfen wir mit von Ihnen auf unserer Website getätigten Handlungen.</p><p>Sie können
                    diesem Tracking jederzeit widersprechen, indem Sie den gesonderten Link, der in jeder E-Mail
                    bereitgestellt wird, anklicken oder uns über einen anderen Kontaktweg informieren. Die Informationen
                    werden solange gespeichert, wie Sie den Newsletter abonniert haben. Nach einer Abmeldung speichern
                    wir
                    die Daten rein statistisch und anonym.</p><p>Ein solches Tracking ist zudem nicht möglich, wenn Sie
                    in
                    Ihrem E-Mail-Programm die Anzeige von Bildern standardmässig deaktiviert haben. In diesem Fall wird
                    Ihnen der Newsletter nicht vollständig angezeigt und Sie können eventuell nicht alle Funktionen
                    nutzen.
                    Wenn Sie die Bilder manuell anzeigen lassen, erfolgt das oben genannte Tracking.</p>
                </div>

            </Template>

        )
    }
}

export default Home