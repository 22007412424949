import React from 'react';

import {Link} from 'react-router-dom';

import PropTypes from 'prop-types';

const Template = (props) => {

    const computeTitle = (title) => {

        let str

        if(typeof title === 'function') {

            try {

                str = title(props) || 'Failed…';

            } catch (e) {

                str = 'Loading…'

            }

        } else if(typeof title === 'string') {

            str = title

        }

        return str;

    };

    const {
        title,
        link,
        back,
        children,
        actionPath,
        ...rest
    } = props;

    return (

        <div className={'position-relative'}>

            <div className="container mt-5">

                <div className={'d-flex align-items-center'}>

                    <h1 className={'mr-4'}>{computeTitle(title)}</h1>

                    {link && link.to(props) && <Link to={link.to(props)}>{link.text}</Link>}

                </div>

                {React.Children.map(children, (child => {

                    return React.cloneElement(child, {...rest})

                }))}

            </div>

            {actionPath &&
            <Link
                to={actionPath}
                className={'btn-primary rounded-circle text-white d-flex justify-content-center align-items-center position-fixed shadow'}
                style={{width: '5rem', height: '5rem', bottom: '4rem', right: '4rem'}}
            >
                <span className="sws-icons" style={{fontSize: '2em'}}>add</span>
            </Link>
            }

        </div>

    );

}

export default Template

Template.defaultProps = {
    back: '/',
    title: 'Title',
};

Template.propTypes = {
    back: PropTypes.string,
}