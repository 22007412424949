import React from 'react';

import { Template } from '@solidwhite/swcp-base/lib/js/views';

import { ImprintText } from './ImprintText.jsx';

const ImprintAndPrivacy = () => {

    return <Template title={'Imprint & Privacy Policy'}>

        <div className="row" style={{margin:'2rem'}}>

            <div className="col-12">

                <ImprintText />

            </div>

        </div>

    </Template>
        
}

export default ImprintAndPrivacy